<template>
  <div class="main-content">
    <b-row>
      <b-col lg="8" xl="8" md="12">
        <b-row>
          <b-col>
            <h4 class="mb-4">Hello Admin!</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" md="4" sm="4">
            <b-card
              class="card-icon text-center mb-30"
              style="background: #eaf5ff"
            >
              <p class="text-muted m-0 text-16" style="color: #000 !important">
                Purchase
              </p>
              <b-icon
                icon="cart"
                variant="primary"
                style="width: 100px; height: 100px"
                class="my-24"
              ></b-icon>
              <p
                class="text-primary text-24 line-height-1 m-0 text-40"
                style="color: #000 !important"
              >
                2.8%
              </p>
            </b-card>
          </b-col>
          <b-col lg="3" md="4" sm="4">
            <b-card
              class="card-icon text-center mb-30"
              style="background: #f9e7ff"
            >
              <p class="text-muted m-0 text-16" style="color: #000 !important">
                Sales
              </p>
              <b-icon
                icon="graph-up"
                color="#fe56c5"
                style="width: 100px; height: 100px"
                class="my-24"
              ></b-icon>
              <p
                class="text-primary text-24 line-height-1 m-0 text-40"
                style="color: #000 !important"
              >
                7.1%
              </p>
            </b-card>
          </b-col>
          <b-col lg="3" md="4" sm="4">
            <b-card
              class="card-icon text-center mb-30"
              style="background: #ddeeff"
            >
              <p class="text-muted m-0 text-16" style="color: #000 !important">
                Stock
              </p>
              <b-icon
                icon="box"
                color="#50a6f7"
                style="width: 100px; height: 100px"
                class="my-24"
              ></b-icon>
              <p
                class="text-primary text-24 line-height-1 m-0 text-40"
                style="color: #000 !important"
              >
                2380
              </p>
            </b-card>
          </b-col>
          <b-col lg="3" md="4" sm="4">
            <b-card
              class="card-icon text-center mb-30"
              style="background: #fefce3"
            >
              <p class="text-muted m-0 text-16" style="color: #000 !important">
                Investment
              </p>
              <b-icon
                icon="currency-dollar"
                color="#ecc103"
                style="width: 100px; height: 100px"
                class="my-24"
              ></b-icon>
              <p
                class="text-primary text-24 line-height-1 m-0 text-40"
                style="color: #000 !important"
              >
                100000
              </p>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="4" xl="4" md="12" class="mb-30">
        <b-card class="h-100">
          <h4 class="card-title m-0">Sales Activities</h4>
          <div class="chart-wrapper">
            <apexchart
              type="line"
              height="250"
              :options="dashboardOne.chartOptions"
              :series="dashboardOne.series"
            ></apexchart>
          </div>
        </b-card>
      </b-col>

      <!-- last-month-salary -->
      <b-col lg="8" xl="8" md="12">
        <b-card title="Recent Activities" body-class="pb-0" class="mb-30">
          <b-row>
            <b-col md="12">
              <table class="table">
                <thead class>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Invoice ID</th>
                    <th scope="col">Product</th>
                    <th scope="col">Date</th>
                    <th scope="col">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Charles Pattinson</td>
                    <td class="font-weight-bold">GID-00014</td>
                    <td>Ruby Stones</td>
                    <td>14-11-2022</td>
                    <td>$357.00</td>
                  </tr>
                  <tr>
                    <td>Charles Pattinson</td>
                    <td class="font-weight-bold">GID-00014</td>
                    <td>Ruby Stones</td>
                    <td>14-11-2022</td>
                    <td>$357.00</td>
                  </tr>
                  <tr>
                    <td>Charles Pattinson</td>
                    <td class="font-weight-bold">GID-00014</td>
                    <td>Ruby Stones</td>
                    <td>14-11-2022</td>
                    <td>$357.00</td>
                  </tr>
                  <tr>
                    <td>Charles Pattinson</td>
                    <td class="font-weight-bold">GID-00014</td>
                    <td>Ruby Stones</td>
                    <td>14-11-2022</td>
                    <td>$357.00</td>
                  </tr>
                  <tr>
                    <td>Charles Pattinson</td>
                    <td class="font-weight-bold">GID-00014</td>
                    <td>Ruby Stones</td>
                    <td>14-11-2022</td>
                    <td>$357.00</td>
                  </tr>
                  <tr>
                    <td>Charles Pattinson</td>
                    <td class="font-weight-bold">GID-00014</td>
                    <td>Ruby Stones</td>
                    <td>14-11-2022</td>
                    <td>$357.00</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- start::top-authors -->
      <b-col lg="4" xl="4" md="12" class="mb-30">
        <b-card class>
          <div class="card-title">Our People</div>
          <b-row>
            <b-col lg="6" md="6" sm="6">
              <b-card
                class="card-icon text-center mb-30 bottom-card"
                style="background: #f9e7ff"
              >
                <b-icon
                  icon="people"
                  color="#fe56c5"
                  style="width: 50px; height: 50px"
                  class="my-24"
                ></b-icon>
                <p
                  class="text-muted m-0 text-16 ml-16"
                  style="color: #000 !important"
                >
                  <span style="font-size: 35px">23</span>
                  <br />
                  Clients
                </p>
              </b-card>
            </b-col>
            <b-col lg="6" md="6" sm="6">
              <b-card
                class="card-icon text-center mb-30 bottom-card"
                style="background: #fefce3"
              >
                <b-icon
                  icon="hand-index"
                  color="#ecc103"
                  style="width: 50px; height: 50px"
                  class="my-24"
                ></b-icon>
                <p
                  class="text-muted m-0 text-16 ml-16"
                  style="color: #000 !important"
                >
                  <span style="font-size: 35px">16</span>
                  <br />
                  Partners
                </p>
              </b-card>
            </b-col>
            <b-col lg="12" md="12" sm="12">
              <b-card
                class="card-icon text-center mb-30 bottom-card"
                style="background: #eaf5ff"
              >
                <b-icon
                  icon="person-check"
                  variant="primary"
                  style="width: 50px; height: 50px"
                  class="my-24"
                ></b-icon>
                <p
                  class="text-muted m-0 text-16 ml-16"
                  style="color: #000 !important"
                >
                  <span style="font-size: 35px">175+</span> HAPPY CUSTOMERS
                </p>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { dashboardOne, dashboardTwo } from "@/data/dashboard2";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Dashboard",
  },

  data: () => ({
    selected: "x",
    dashboardOne,
    dashboardTwo,
  }),
  methods: {},
};
</script>

<style scoped>
.bottom-card .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
</style>
